







































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'TabConfig',
  components: {}
})
// 此为  vue-class-component Mixins的写法
// this.widget数据来自于 vuex 改变后 会影响到视图
export default class extends Mixins(ElePropertiesMixins) {
  created() {
    this.initData();
  }
  // 初始化页签数据 当children为空时 判断为新增;不为空时 回显数据
  initData() {
    if (this.widget.compType != 'tab') return;
    const components = this.widget.properties.components;
    if (components.length) {
    } else {
      this.widget.properties.components = [];
    }
  }
}
